import '../css/H5dwonload.css';
import selectIcon from '../assets/select-icon.png';

import ios from '../assets/h5/ios.png'
import andriod from '../assets/h5/andriod.png'
import macos from '../assets/h5/macos.png'
import tv from '../assets/h5/tv.png'
import windows from '../assets/h5/windows.png'
import jszx from '../assets/jszx.png';
import bxs from '../assets/bxs.png';
import ddts from '../assets/ddts.png';
import wsts from '../assets/ddts.png';
import mgg from '../assets/mgg.png';
import step1 from '../assets/step1.png';
import step2 from '../assets/step2.png';
import step3 from '../assets/step3.png';

import {  message } from 'antd';

import { useTranslation} from 'react-i18next';
import { useState } from 'react';

function H5dwonload(props) {

    const list = global.urlList.data
    const {item} = props
    // console.log(item,"deviceName.name>>>>>>")
    const { t } = useTranslation();
    const [deviceName,setDeviceName] = useState(item.storeName?item.storeName:"iOS AppStore")
    const [type,setType] =useState(item.name?item.name:"ios")
    const infoList = [
        {
            content: t('download.fast')
        },
        {
            content: t('download.safe')
        },
        {
            content:t('download.unlimite')
        },
        {
            content: t('download.free')
        },
        {
            content: t('download.invite')
        }
    ]
    const downloadList = [
        {
            img: ios,
            name: "iOS",
            storeName:"iOS AppStore"
        },
        {
            img: andriod,
            name: "Android",
            storeName:"Android APK"
        },
        {
            img: macos,
            name: "MacOS",
            storeName:"Mac 应用程序"
        },
        {
            img: windows,
            name: "Windows",
            storeName:"Windows 应用程序"
        },
        {
            img: tv,
            name: "TV",
            storeName:"TV 应用程序"
        }

    ]

    const vipList = [
        {
            img: jszx,
            content: t('download.accele')
        },
        {
            img: bxs,
            content: t('download.unspeed')
        },
        {
            img: ddts,
            content: t('download.multi')
        },
        {
            img: wsts,
            content: t('download.speed')
        },
        {
            img: mgg,
            content: t('download.noad')
        }
    ]

    const clientList = [
        {
            img: step1,
            content: t('download.step1')
        },
        {
            img: step2,
            content: t('download.step2')
        },
        {
            img: step3,
            content: t('download.step3')
        }

    ]
    function handleDownload(item){
        setDeviceName(item.storeName)
        setType(item.name)
    }

    function onDownload(){
        let data =''
        for (const ite of list) {
            if(type.toLowerCase() === ite.version_type.toLowerCase()){
                data = ite
            }          
        }

        if(data.version_down_url){
            window.open(data.version_down_url,"_blank")
        }else{
            message.info(data? data.version_tips : t('download.tips'),1);
        }
    }

    return (
        <div className='H5dwonload'>
            <p>{t('download.loadqlm')}</p>
            <span>{t('download.available')}</span>
            <div className='info-list'>
                <div style={{ marginTop: "-88px" }}>
                    {
                        infoList.map((item, index) => {
                            return (
                                <div className='info-list-item' key={index}>
                                    <img src={selectIcon} alt=''></img>
                                    <p>{item.content}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='h5download-content'>
                <button onClick={onDownload}>{t('download.immediately')+deviceName}</button>
                <p style={{marginTop:"20px"}}>{t('download.alldevice')}</p>
                <span>{t('download.click')}</span>
                <div className='platform-list'>
                    {
                        downloadList.map((item,index)=>{
                            return(
                                <div className='platform-list-item' key={index} onClick={()=>handleDownload(item)}>
                                    <img src={item.img} alt=''></img>
                                    <span>{item.name}</span>
                                </div>
                            )
                        })
                    }                 
                </div>
                <p>{t('download.joinvip')}</p>
                <div className='h5vip-list'>
                    {
                        vipList.map((item,index)=>{
                            return(
                                <div className='h5vip-list-item' key={index}>
                                    <img src={item.img} alt=''></img>
                                    <p>{item.content}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <p>{t('download.how')}</p>
                <span>{t('download.keep3step')}</span>
                <div className='h5vip-list'>
                    {
                        clientList.map((item,index)=>{
                            return(
                                <div className='h5vip-list-item' key={index}>
                                    <img src={item.img} alt=''></img>
                                    <p style={{fontSize:"20px"}}>{item.content}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}

export default H5dwonload