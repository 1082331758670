
import '../css/H5sidebar.css'
import homeicon from '../assets/h5/homeicon.png';
import membericon from '../assets/h5/membericon.png';
import downloadicon from '../assets/h5/downloadicon.png';
import rebateicon from '../assets/h5/rebateicon.png';
import arrow from '../assets/h5/arrow.png';
import unselect from '../assets/h5/unselect.png';
import select from '../assets/h5/select.png';
import React ,{useState} from 'react';

import { useTranslation} from 'react-i18next';

function H5sidebar({onPageItem,onChangeLanguage}) {

    const { t } = useTranslation();
    const [languageIndex,setLanguageIndex] =useState(0)
    const btnList = [
        {
            img: homeicon,
            name: t('slidebar.home')
        },
        {
            img: membericon,
            name: t('slidebar.member')
        },
        {
            img: downloadicon,
            name: t('slidebar.download')
        },
        {
            img: rebateicon,
            name: t('slidebar.rebate')
        }
    ]

    const languageList = [
        {
            name: "简体中文",
            code:"CN"
        },
        {
            name: "繁體中文",
            code:"TW"
        },
        {
            name: "English",
            code:"EN"
        }
    ]

    function onChangePage(index){
        onPageItem(index)
    }

    function onLanguage(index){
        setLanguageIndex(index)
    }

    function onConfirm(){
        onChangeLanguage(languageList[languageIndex].code)
    }

    return (
        <div className='H5sidebar'>
            <div className='btn-list'>
                {
                    btnList.map((item, index) => {
                        return (
                            <div className='btn-list-item' key={index} onClick={()=>onChangePage(index)}>
                                <div className='btn-list-item-left'>
                                    <img src={item.img} alt=''></img>
                                    <p>{item.name}</p>
                                </div>
                                <img src={arrow} alt=''></img>
                            </div>
                        )
                    })
                }
            </div>
            <div className='language-list'>
                <p>{t('slidebar.selectLanguage')}</p>
                {
                    languageList.map((item, index) => {
                        return (
                            <div className='language-list-item' key={index} onClick={()=>onLanguage(index)}>
                                <p>{item.name}</p>
                                <img src={languageIndex === index ? select:unselect } alt=''></img>
                            </div>
                        )
                    })
                }
                <button onClick={onConfirm}>{t('slidebar.sure')}</button>
            </div>
        </div>
    )
}

export default H5sidebar