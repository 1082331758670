import '../css/Invite.css';
import useTips from '../assets/useTips.png';
import inviteIcon from '../assets/invite-icon.png';
import pcIntive from '../assets/pcIntive.png';
import pcnew from '../assets/pcnew.png';

import { useTranslation } from 'react-i18next';

function Invite({onTry}) {

    const { t } = useTranslation();
    function onExperience() {
        onTry()
    }

    return (
        <div className="InviteBg">
            <div className='invite-list'>
                <div className='invite-list-left'>
                    <img className='tipsicon' src={useTips} alt='' />
                    <div className='invitelayout'>
                        <div className='invite-item'>
                            <img width={24} src={pcIntive} alt='' />
                            <span style={{ fontSize: "22px", color: "#fff", marginLeft: "10px" }}>{t('home.invite')}</span>
                        </div>

                        <div className='invite-item'>
                            <img width={24} src={pcnew} alt="" />
                            <span style={{ fontSize: "22px", color: "#fff", marginLeft: "10px" }}>{t('home.discount')}</span>
                        </div>
                    </div>
                    <button className='btn' onClick={onExperience}>{t('home.btn')}</button>
                </div>

                <img className='inviteicon' src={inviteIcon} alt='' />
            </div>


        </div>
    )
};


export default Invite;