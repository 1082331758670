import '../css/H5bottom.css';

import ios from '../assets/h5/ios.png'
import andriod from '../assets/h5/andriod.png'
import macos from '../assets/h5/macos.png'
import tv from '../assets/h5/tv.png'
import windows from '../assets/h5/windows.png'

import email from '../assets/email.png'
import group from '../assets/group.png'
import twitter from '../assets/twitter.png'
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import {  message } from 'antd';

function H5bottom({onDownload }) {
    const { t, i18n } = useTranslation();
    const downloadList = [
        {
            img: ios,
            name: "iOS",
            storeName:"iOS AppStore"
        },
        {
            img: andriod,
            name: "Android",
            storeName:"Android APK"
        },
        {
            img: macos,
            name: "MacOS",
            storeName:"Mac 应用程序"
        },
        {
            img: windows,
            name: "Windows",
            storeName:"Windows 应用程序"
        },
        {
            img: tv,
            name: "TV",
            storeName:"TV 应用程序"
        }

    ]

    const connectList = [
        {
            img: email,
            name: t('bottom.email'),
            content: "复制"
        },
        {
            img: group,
            name: t('bottom.group'),
            content: '立即加入'
        },
        {
            img: twitter,
            name: t('bottom.twetter'),
            content: t('bottom.follow')
        },
    ]

    function onTxt(index) {

        console.log(index)
        switch (index) {
            case 9:
                window.open('./illustrate.html?lang=' + i18n.language + '&index=' + 2)
                break
            case 8:
                window.open('./illustrate.html?lang=' + i18n.language  + '&index=' + 3)
                break
        }

    }

    function toDownload(name){
        onDownload(name)
    }


    function toConnect(index){
        if(index === 1){
            window.open('https://t.me/qianlimavpn')
        }else if(index === 2){
            window.open('https://twitter.com/qlmvpn')
        }else{
            if(copy("qianlimavpn@gmail.com")){
                message.info(t('content.copySuccess'),1);
            }
        }
    }

    return (
        <div className='H5bottom'>
            <div className='downlist'>
                {
                    downloadList.map((item, index) => {
                        return (
                            <div className='downlist-item' key={index} onClick={()=>toDownload(item)}>
                                <img src={item.img} alt=''></img>
                                <span>{item.name}</span>
                            </div>
                        )
                    })
                }
            </div>
            <div className='connect-list'>
                {
                    connectList.map((item, index) => {
                        return (
                            <div className='connect-list-item' key={index} onClick={()=>toConnect(index)}>
                                <img src={item.img} alt=''></img>
                                <p>{item.name}</p>
                                <p style={{ fontSize: "9px" }}>{item.content}</p>
                            </div>
                        )
                    })
                }
            </div>
            <div className='bottom'>
                <div className='txt-btn'>
                    <p onClick={() => onTxt(8)}>{t('bottom.privacy')}</p>
                    <p onClick={() => onTxt(9)}>{t('bottom.service')}</p>
                </div>

                <p className='noinfo'>{t('bottom.info1')}</p>
            </div>

        </div>
    )
}

export default H5bottom