
import '../css/Bottom.css';
import { useTranslation } from 'react-i18next';

function Bottom({ onBottom }) {
  const { t, i18n } = useTranslation();
  console.log(i18n.language)

  function toContent(index) {


    switch (index) {
      case 1:
        onBottom(index)
        break
      case 2:
        window.open('./illustrate.html?lang=' + i18n.language + '&index=' + index)
        break
      case 3:
        window.open('./illustrate.html?lang=' + i18n.language + '&index=' + index)
        break
    }



  }

  return (
    <div className="Bottom">
      <div className='btnlist'>
        <p onClick={() => toContent(1)}>{t('bottom.contactUs')}</p>
        <p onClick={() => toContent(2)}>{t('bottom.service')}</p>
        <p onClick={() => toContent(3)}>{t('bottom.privacys')}</p>
      </div>
      <div className='directions'>
        <p>{t('bottom.info1')}</p>
        {/* <p>{t('bottom.info2')}</p> */}
      </div>
    </div>
  )
}


export default Bottom;