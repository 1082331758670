import '../css/Connect.css';
import email from '../assets/email.png'
import group from '../assets/group.png'
import twitter from '../assets/twitter.png'
import bg from '../assets/bg4.png'
import zsgs from '../assets/zsgs.png'
import nrjs from '../assets/nrjs.png'
import zzjs from '../assets/zzjs.png'
import tyh from '../assets/tyh.png'
import xy from '../assets/xy.png'
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import {  message } from 'antd';


function Connect() {
    const { t } = useTranslation();

    const connectList = [
        {
            img: email,
            name: t('bottom.email'),
            content: "复制"
        },
        {
            img: group,
            name: t('bottom.group'),
            content: '立即加入'
        },
        {
            img: twitter,
            name: t('bottom.twetter'),
            content: t('bottom.follow')
        },
    ]

    function toConnect(index) {
        if (index === 1) {
            window.open('https://t.me/qianlimavpn')
        } else if (index === 2) {
            window.open('https://twitter.com/qlmvpn')
        }else{
            if(copy("qianlimavpn@gmail.com")){
                message.info(t('content.copySuccess'),1);
            }
        }

    }

    return (
        <div className="Connect">
            <p>{t('bottom.contactUs')}</p>
            <div className='ContactList'>
                {
                    connectList.map((item, index) => {
                        return (
                            <div className='listItem' key={index} onClick={() => toConnect(index)}>
                                <img src={item.img} alt=""></img>
                                <p>{item.name}</p>
                                <p style={{ fontSize: "20px", marginTop: "10px" }}>{item.content}</p>
                            </div>
                        )
                    })
                }
            </div>
            <div className='introduce'>
                <p>{t('content.introduce')}</p>
                <span>{t('content.info')}</span>
            </div>
            <img className='map' src={bg} alt=''></img>
            <div className='content'>
                <div className='content-item'>
                    <div className='content-item-info' style={{ alignItems: "flex-start" }}>
                        <p>{t('content.zsgs.title')}</p>
                        <span>{t('content.zsgs.content')}</span>
                    </div>
                    <img src={zsgs} alt=""></img>
                </div>
                <div className='content-item'>
                    <img src={nrjs} alt=""></img>
                    <div className='content-item-info' style={{ alignItems: "flex-end" }}>
                        <p>{t('content.nrjs.title')}</p>
                        <span style={{ textAlign: 'end' }}>{t('content.nrjs.content')}</span>
                    </div>

                </div>
                <div className='content-item' >
                    <div className='content-item-info' style={{ alignItems: "flex-start" }}>
                        <p>{t('content.zzjs.title')}</p>
                        <span>{t('content.zzjs.content')}</span>
                    </div>
                    <img src={zzjs} alt=""></img>
                </div>
                <div className='content-item'>
                    <img src={tyh} alt=""></img>
                    <div className='content-item-info' style={{ alignItems: "flex-end" }}>
                        <p>{t('content.tyh.title')}</p>
                        <span style={{ textAlign: 'end' }}>{t('content.tyh.content')}</span>
                    </div>

                </div>
                <div className='content-item'>
                    <div className='content-item-info' style={{ alignItems: "flex-start" }}>
                        <p>{t('content.xy.title')}</p>
                        <span>{t('content.xy.content')}</span>
                    </div>
                    <img src={xy} alt=""></img>
                </div>
            </div>

        </div>
    )
}

export default Connect;