import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
const i18nInstance = i18next.createInstance()
i18nInstance.use(initReactI18next);

const locales = {
    "en-US": require('./en-US.json'),
    "zh-CN": require('./zh-CN.json'),
    "zh-TW": require('./zh-TW.json'),
  };
//   console.log(locales['en-US'])

const resources = {
    EN: {
        translation: locales['en-US'],
    },
    ZH: {
        translation: locales['zh-CN'],
    },
    TW: {
        translation: locales['zh-TW'],
    },
}

i18nInstance.init(
    {
        lng: "EN",
        // 后备语言，如果用户语言的翻译不可用时使用此顺序
        fallbackLng: ["ZH", "EN"],
        // 如果需要调试可以打开
        // debug: process.env.NODE_ENV === "development",
        ns: ["translation"],
        // 默认值就是 translation，可以进行设置
        defaultNS: "translation",
        resources: resources,
    }
)

export default i18nInstance;
