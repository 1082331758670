
import './App.css';
import Invite from './components/Invite.js';
import Top from './components/Top.js';
import Advantage from './components/Advantage.js';
import Bottom from './components/Bottom.js';
import Connect from './components/Connect.js';
import Member from './components/Member.js';
import Rebate from './components/Rebate.js';
import Download from './components/Download.js';
import React, { useState,useRef,useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
// import { useTranslation } from 'react-i18next';

function App() {

  document.documentElement.scrollTop = 0
  const { i18n } = useTranslation();
  const [tabIndex, setTabIndex] = useState('1')
  const [name, setName] = useState('iOS AppStore')
  const [bottomIndex, setBottomIndex] = useState(0)
  const [list, setList] = useState([])
  const topRef = useRef()

  useEffect(() => {
    console.log(i18n.language)
    let lang = ''
    if(i18n.language === 'CN'){
      lang=2
    }else if(i18n.language === 'TW'){
      lang=3
    }else{
      lang=1
    }
    
    axios.post(`https://qlm.hyys.info/guanwang/package`, { oauth_type: global.oauthType, lang: lang })
      .then(res => {
        setList(res.data.data)
        console.log(res.data.data)
      })


  }, [])


  function onChange(index) {
    setTabIndex(index)
    setBottomIndex(0)
   
    //console.log("getTabState>>>>>>>", tabIndex)
  }

  function onChangeBottom(index) {
    setBottomIndex(index)
    setTabIndex(0)
    //console.log("onChangeBottom>>>>>>>", bottomIndex)
  }

  function onTry(name) {
    console.log(name,"name>>>>>")
      topRef.current.changeTab('3')
      setName(name)
 
    // topRef.current.changeTab('3')
   // onChange('3')
  }

  function onChangeLang(list){
    console.log(list)
    setList(list ? list : global.member.data)
  }


  return (
    <div className="App">
      <Top ref={topRef} onChangeTab={onChange} onChangeLang={onChangeLang}></Top>
      {/* 首页 */}
      {
        (() => {
          if (tabIndex === '1') {
            return (
              <>
                <Invite onTry={onTry}></Invite>
                <Advantage onDownload={onTry}></Advantage>
              </>
            )
          } else if (tabIndex === '2') {
            return (
              <>
                <Member list={list}></Member>
              </>
            )
          } else if (tabIndex === '3') {
            return (
              <>
                <Download item={name}></Download>
              </>
            )

          } else if (tabIndex === '4') {
            return (
              <>
                <Rebate onDownload={onTry}></Rebate>
              </>
            )

          }
        })()
      }
      {/* 底部文字按钮交互 */}
      {
        (() => {
          console.log(tabIndex, "tabIndex")
          if (tabIndex === 0) {
            return (
              <>

                {
                  (() => {
                    if (bottomIndex === 1) {
                      return (
                        <>
                          <Connect></Connect>
                        </>
                      )
                    }
                  })()
                }

              </>
            )
          }
        })()
      }

      <Bottom onBottom={onChangeBottom}></Bottom>
    </div>
  );
}



export default App;
