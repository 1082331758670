import React, { forwardRef, useImperativeHandle, useState } from "react";
import '../css/Top.css';
import { Tabs } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import logo from '../assets/logo.png'
import unselect from '../assets/h5/unselect.png';
import select from '../assets/h5/select.png';
import axios from 'axios';
// import ClipboardJS from 'clipboard';

import { useTranslation } from 'react-i18next';


const Top = forwardRef(({
    onChangeTab, onChangeLang
}, ref) => {
    useImperativeHandle(ref, (newval) => ({
        changeTab: (newval) => {
            handleTab(newval)
        }
    }))

    const { t, i18n } = useTranslation();

    const languageList = [
        {
            name: "简体中文(CN)",
            code: "CN"
        },
        {
            name: "繁體中文(TW)",
            code: "TW"
        },
        {
            name: "English(EN)",
            code: "EN"
        }
    ]

    const items = [
        {
            key: '1',
            label: t('slidebar.home'),
        },
        {
            key: '2',
            label: t('slidebar.member'),
        },
        {
            key: '3',
            label: t('slidebar.download'),
        },
        {
            key: '4',
            label: t('slidebar.rebate'),
        },
    ];

    const [isShow, setIsShow] = useState(false);
    const [language, setLanguage] = useState(languageList[2].name);
    const [code, setCode] = useState(languageList[0].code);
    const [activeKey, setActiveKey] = useState('1')

    function handleTab(e) {
        console.log("handleTab>>>>>>>.")
        setActiveKey(e)
        onChangeTab(e)
    }

    function onLanguage() {
        // console.log("onLanguage>>>>>>",language)
        setIsShow(!isShow)
    }

    function onChangeLanguae(item) {

        i18n.changeLanguage(item.code)
        setLanguage(item.name)
        setCode(item.code)
        setIsShow(false)
        console.log(global.oauthType)
        let lang = ''
        if (i18n.language === 'CN') {
            lang = 2
        } else if (i18n.language === 'TW') {
            lang = 3
        } else {
            lang = 1
        }
        axios.post(`https://qlm.hyys.info/guanwang/package`, { oauth_type: global.oauthType, lang: lang })
            .then(res => {
                console.log(res.data.data)
                onChangeLang(res.data.data)
            })
    }


    return (
        <div className="Top">
            <div className='top-left'>
                <img src={logo} alt='' />
                <Tabs defaultActiveKey='1' activeKey={activeKey} tabBarGutter={92} items={items} onTabClick={handleTab} tabBarStyle={{ marginLeft: "65px" }} />
            </div>


            <div className="line"></div>
            {/* <a style={{color:"#fff",fontSize:"20px"}} href="./assets/html/customer.html" target={}>测试</a> */}
            <div className='language'>
                <div style={{ display: "flex", alignItems: "center" }} onClick={onLanguage}>
                    <CaretDownOutlined style={{ color: "#fff" }} />
                    <span style={{ display: "block", width: "120px" }}>{language}</span>
                </div>

                {
                    isShow ? <div className='languageBg'>
                        {
                            languageList.map((item, index) => {
                                return (
                                    <div className="language-item" onClick={() => onChangeLanguae(item)} key={index}>
                                        <img src={code === item.code ? select : unselect} alt=""></img>
                                        <p >{item.name}</p>
                                    </div>

                                )

                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    );
})

// function Top({ onChangeTab }) {


// }

export default Top;