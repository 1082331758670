
import '../css/Member.css';
import alipay from '../assets/alipay.png';
import wechat from '../assets/wechat.png';
import visa from '../assets/visa.png';
import React, { useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard, Mousewheel } from "swiper/core";

import { useTranslation } from 'react-i18next';
function Member({ list }) {
    console.log(list, "Member>>>>>>")
    const { t } = useTranslation();
    SwiperCore.use([Keyboard, Mousewheel]);
    const [currentIndex, setCurrentIndex] = useState(0)
    const payList = [
        {
            img: alipay,
            name: "支付宝"
        },
        {
            img: wechat,
            name: "微信"
        },
        {
            img: visa,
            name: "VISA"
        }
    ]

    function onSelect(index) {
        setCurrentIndex(index)
    }

    return useMemo(
        () => (
            <div className='Member'>
                <p>{t('member.memberPrice')}</p>
                <div className='pay'>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={-30}
                        mousewheel={true}
                        onSlideChange={(e) => {
                            setCurrentIndex(e.realIndex)

                        }}
                        style={{ width: '1300px', marginTop: '66px' }}
                    >
                        {

                            list.map((item, index) => {
                                return (
                                    <SwiperSlide className='swiper-s' key={index}>
                                        <div className={currentIndex === index ? 'pay-item-active' : 'pay-item'} key={index} onClick={() => onSelect(index)}>
                                            <div className='recom'>
                                                <div className={item.pack_activity_pic ? 'recom-txt' : ''}> {item.pack_activity_pic ? item.pack_activity_pic : ''}</div>
                                            </div>
                                            <p style={{ marginTop: "10px" }}>{item.pack_name}</p>
                                            <p style={{ margin: "21px 0 40px 0", fontSize: "38px", color: "#fff" }}>¥ <span>{item.pack_price}</span></p>
                                            <div className='discount'> {item.pack_describe}</div>
                                            <p>{t('member.originalPrice', { price: item.original_price })}</p>

                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }

                    </Swiper>

                </div>
                <div className='pay-method'>
                    <p>{t('member.payfunc')}</p>
                    <div className='pay-content'>
                        {

                            payList.map((item, index) => {
                                return (
                                    <div className='pay-method-item' key={index}>
                                        <img src={item.img} alt=''></img>
                                        <span>{item.name}</span>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </div>
        ), [list]

    )
}
export default Member; 