
import '../css/H5member.css';
import alipay from '../assets/alipay.png';
import wechat from '../assets/wechat.png';
import visa from '../assets/visa.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import React, { useState } from "react";


import { useTranslation } from 'react-i18next';

function H5member() {

    const { t } = useTranslation();
    // const payicon = { "alipay": alipay, "wechat": wechat, "visa": visa }
    const [currentIndex, setCurrentIndex] = useState(0);
    const list = global.member.data
    const payList = [
        {
            img: alipay,
            name: "支付宝"
        },
        {
            img: wechat,
            name: "微信"
        },
        {
            img: visa,
            name: "VISA"
        }
    ]


    return (

        <div className='H5member'>
            <p>{t('member.memberPrice')}</p>
            <div className='member-content'>
                <Swiper
                    spaceBetween={10}
                    slidesPerView={2}
                    centeredSlides={true}
                    loop={true}
                    loopPreventsSliding={true}
                    onSlideChange={(e) => {
                        setCurrentIndex(e.realIndex)

                    }}
                >
                    {

                        list.map((item, index) => {
                            return (
                                <SwiperSlide style={{ width: '186px', height: '220px' }} key={index}>
                                    <div className={index === currentIndex ? "swiper-slide-select" : "swiper-slide-normal"}>
                                        <div className='h5recom' >
                                        <div className={item.pack_activity_pic?'h5-recom-txt':''}> {item.pack_activity_pic?item.pack_activity_pic:''}</div>
                                        </div>
                                        <p>{item.pack_name}</p>
                                        <p style={{ color: "#fff", fontSize: "18px" }}>¥<span style={{ fontSize: "35px" }}>{item.pack_price}</span></p>
                                        <div className='discount-bg'>{item.pack_describe}  </div>
                                        <p>{t('member.originalPrice', { price: item.original_price })}</p>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }

                </Swiper>
                <p>{t('member.payfunc')}</p>
                <div className='pay-list'>
                    {
                        payList.map((item, index) => {
                            return (
                                <div className='pay-list-item' key={index}>
                                    <img src={item.img} alt=''></img>
                                    <span>{item.name}</span>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}

export default H5member