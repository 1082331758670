import '../css/H5home.css';
import txtimg from '../assets/h5/h5-txt.png'
import bg from '../assets/h5/bg.png'
import zksd from '../assets/zksd.png';
import fgtd from '../assets/fgtd.png';
import ddts from '../assets/ddts.png';
import smll from '../assets/smll.png';
import aqfw from '../assets/aqfw.png';
import cl from '../assets/cl.png';
import freevip from '../assets/freevip.png';

import { useTranslation} from 'react-i18next';

function H5home({onPage}) {
    const { t } = useTranslation();
    // console.log(t('home.list'))
    const list = [
        {
            src: zksd,
            title: t('home.list.zksd.title'),
            content: t('home.list.zksd.content')
        },
        {
            src: fgtd,
            title: t('home.list.fgtd.title'),
            content: t('home.list.fgtd.content')
        },
        {
            src: ddts,
            title: t('home.list.ddts.title'),
            content: t('home.list.ddts.content')
        },
        {
            src: smll,
            title: t('home.list.smll.title'),
            content: t('home.list.smll.content')
        },
        {
            src: aqfw,
            title: t('home.list.aqfw.title'),
            content: t('home.list.aqfw.content')
        },
        {
            src: cl,
            title: t('home.list.cl.title'),
            content: t('home.list.cl.content')
        },
        {
            src: freevip,
            title: t('home.list.freevip.title'),
            content: t('home.list.freevip.content')
        }
    ]

    function onRegister() {
        onPage()
       // console.log("onRegister>>>>")
    }

    return (
        <div className='H5home'>
            <img src={txtimg} alt=''></img>
            <button onClick={onRegister}>{t('home.inviteBtn')}</button>
            <img className='bg' src={bg} alt=''></img>
            <div className='info'>
                <p>{t('home.whyselect')}</p>
                {
                    list.map((item,index)=>{
                        return(
                            <div className='list-item' key={index}>
                                <img src={item.src} alt=''></img>
                                <p>{item.title}</p>
                                <span>{item.content}</span>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default H5home