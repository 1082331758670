
import zksd from '../assets/zksd.png';
import fgtd from '../assets/fgtd.png';
import ddts from '../assets/ddts.png';
import smll from '../assets/smll.png';
import aqfw from '../assets/aqfw.png';
import cl from '../assets/cl.png';
import ios from '../assets/h5/ios.png'
import andriod from '../assets/h5/andriod.png'
import macos from '../assets/h5/macos.png'
import tv from '../assets/h5/tv.png'
import windows from '../assets/h5/windows.png'
import freevip from '../assets/freevip.png';
import '../css/Advantage.css';

import { useTranslation } from 'react-i18next';

function Advantage({onDownload}) {
    const { t } = useTranslation();

    const list = [
        {
            src: zksd,
            title: t('home.list.zksd.title'),
            content: t('home.list.zksd.content')
        },
        {
            src: fgtd,
            title: t('home.list.fgtd.title'),
            content: t('home.list.fgtd.content')
        },
        {
            src: ddts,
            title: t('home.list.ddts.title'),
            content: t('home.list.ddts.content')
        },
        {
            src: smll,
            title: t('home.list.smll.title'),
            content: t('home.list.smll.content')
        },
        {
            src: aqfw,
            title: t('home.list.aqfw.title'),
            content: t('home.list.aqfw.content')
        },
        {
            src: cl,
            title: t('home.list.cl.title'),
            content: t('home.list.cl.content')
        }
    ]

    const deviceList = [
        {
            img: ios,
            name: "iOS",
            storeName:"iOS AppStore"
        },
        {
            img: andriod,
            name: "Android",
            storeName:"Android APK"
        },
        {
            img: macos,
            name: "MacOS",
            storeName:"Mac 应用程序"
        },
        {
            img: windows,
            name: "Windows",
            storeName:"Windows 应用程序"
        },
        {
            img: tv,
            name: "TV",
            storeName:"TV 应用程序"
        }

    ]

    function toDownload(item){
        onDownload(item)
    }

    return (
        <div className='Advantagelayout'>
            {/* <img width={475} height={56} src={whyselect} style={{ marginTop: "48px" }} alt=""></img> */}
            <p>{t('home.whyselect')}</p>
            <div className='container'>
                {
                    list.map((item, index) => {
                        return (

                            <div className="item" key={index}>
                                <img width={180} src={item.src} alt=''></img>
                                <p style={{ color: "#fff", fontSize: "30px" }}>{item.title}</p>
                                <span>{item.content}</span>
                            </div>
                        )

                    })
                }
            </div>
            <p>{t('download.alldevice')}</p>
            <span>{t('download.click')}</span>
            <div className='device-list'>
                {
                    deviceList.map((item, index) => {
                        return (
                            <div className='device-list-item' key={index} onClick={()=>toDownload(item)}>
                                <img src={item.img} alt=''></img>
                                <span>{item.name}</span>
                            </div>
                        )
                    })
                }
            </div>
            <p style={{ fontSize: "18px", color: "rgba(255, 255, 255, 0.4)", marginTop: "40px" }}>{t('download.downloadtips')}</p>
            <div className='free-layout'>
                <img src={freevip} alt=''></img>
                <div className='free-layout-right'>
                    <p>{t('home.list.freevip.title')}</p>
                    <span>{t('home.list.freevip.content')}</span>
                </div>
            </div>

        </div>
    )
}

export default Advantage;