import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import H5 from './H5';
import I18n from './locale/I18n.js';
import axios from 'axios';




const root = ReactDOM.createRoot(document.getElementById('root'));
getOperationSys()
root.render(
  window.innerWidth < 620 ? <H5 /> : <App />
);



function getOperationSys() {

  var oauthType;
  var agent = navigator.userAgent.toLowerCase();

  let isIos = /iphone/i.test(agent)
  let isAndriod = /android/i.test(agent)
  let isMac = /macintosh|mac os x/i.test(agent)
  let isWin = /windows|win32/i.test(agent);
  let ispad = /iPad/i.test(agent);
  if (isIos || ispad) {
    oauthType = 'ios'
  } else if (isAndriod) {
    oauthType = 'andriod'
  } else if (isMac) {
    oauthType = 'mac'
  } else if (isWin) {
    oauthType = 'windows'
  }

  global.oauthType = oauthType
  let lang = ''
  if (I18n.language === 'CN') {
    lang = 2
  } else if (I18n.language === 'TW') {
    lang = 3
  } else {
    lang = 1
  }
  axios.post(`https://qlm.hyys.info/guanwang/package`, { oauth_type: oauthType, lang: lang })
    .then(res => {
      global.member = {
        data: res.data.data
      }
      //console.log(res.data.data)
    })

  axios.post(`https://qlm.hyys.info/guanwang/version`)
    .then(res => {
      global.urlList = {
        data: res.data.data
      }
      console.log(res.data.data)
    })

}

getIP()

function reqAffLog(aff, ip) {
  axios.post(`https://qlm.hyys.info/guanwang/affLog`, {
    aff: aff, ip: ip
  })
    .then(res => {
      console.log(res, "reqAffLog>>>>>>>>>>>")
    })
}

function getIP() {
  const urlParams = new URLSearchParams(window.location.search);
  const aff = urlParams.get('aff'); // 获取 aff 参数的值
  console.log(aff, "affLog>>>>>>>")
  if (aff) {
    axios.post(`https://qlm.hyys.info/guanwang/getIp`)
      .then(res => {
        console.log(res.data.data.ip, "ip>>>>>>>")
        reqAffLog(aff, res.data.data.ip)
      })
  }

}


