import '../css/Download.css';
import bg from '../assets/bg3.png';
import selectIcon from '../assets/select-icon.png';
import ios from '../assets/ios.png';
import andriod from '../assets/h5/andriod.png'
import macos from '../assets/h5/macos.png'
import tv from '../assets/h5/tv.png'
import windows from '../assets/h5/windows.png'
import jszx from '../assets/jszx.png';
import bxs from '../assets/bxs.png';
import ddts from '../assets/ddts.png';
import wsts from '../assets/ddts.png';
import mgg from '../assets/mgg.png';
import step1 from '../assets/step1.png';
import step2 from '../assets/step2.png';
import step3 from '../assets/step3.png';

import {  message } from 'antd';

import { useTranslation} from 'react-i18next';
import { useState } from 'react';
function Download(props) {
    // console.log(props,"props>>>>>>>>>>")
     const {item} = props
    console.log(item,"item>>>>>")
     const list = global.urlList.data
   
    const { t } = useTranslation();
    const [storeName,setStoreName] =useState(item?.storeName ? item?.storeName:"iOS AppStore")
    const [type,setType] =useState(item?.name?item.name:'ios')
    const downloadTips = "下载用于智能电视的 VPN（例如 Apple TV、Android TV、Samsung TV），\n在游戏机上试用（例如 Xbox 或 PlayStation），或者在路由器上设置以保护您的家庭网络。"
    const infoList = [
        {
            content: t('download.fast')
        },
        {
            content: t('download.safe')
        },
        {
            content:t('download.unlimite')
        },
        {
            content: t('download.free')
        },
        {
            content: t('download.invite')
        }
    ]

    const downloadList = [
        {
            img: ios,
            name: "iOS",
            storeName:"iOS AppStore"
        },
        {
            img: andriod,
            name: "Android",
            storeName:"Android APK"
        },
        {
            img: macos,
            name: "MacOS",
            storeName:"Mac 应用程序"
        },
        {
            img: windows,
            name: "Windows",
            storeName:"Windows 应用程序"
        },
        {
            img: tv,
            name: "TV",
            storeName:"TV 应用程序"
        }

    ]

    const vipList = [
        {
            img: jszx,
            content: t('download.accele')
        },
        {
            img: bxs,
            content: t('download.unspeed')
        },
        {
            img: ddts,
            content: t('download.multi')
        },
        {
            img: wsts,
            content: t('download.speed')
        },
        {
            img: mgg,
            content: t('download.noad')
        }
    ]

    const clientList = [
        {
            img: step1,
            content: t('download.step1')
        },
        {
            img: step2,
            content: t('download.step2')
        },
        {
            img: step3,
            content: t('download.step3')
        }

    ]
   

    function onDownload() {
        let data =''
        for (const ite of list) {
            if(type.toLowerCase() === ite.version_type.toLowerCase()){
                data = ite
            }          
        }

        if(data.version_down_url){
            window.open(data.version_down_url,"_blank")
        }else{
            message.info(data? data.version_tips : t('download.tips'),1);
        }
    }

    function toDownload(item){
        
        setStoreName(item.storeName)
        setType(item.name)
    }

    return (
        <div className='Download'>
            <div className='top-layout'>
                <div className='Download-left'>
                    <p>{t('download.loadqlm')}</p>
                    <span>{t('download.available')}</span>
                    {
                        infoList.map((item, index) => {
                            return (
                                <div className='info-item' key={index}>
                                    <img src={selectIcon} alt=""></img>
                                    <p>{item.content}</p>
                                </div>
                            )
                        })
                    }
                    <button onClick={onDownload}>{ storeName} {t('slidebar.download')}</button>
                </div>
                <img src={bg} alt=''></img>
            </div>
            <p>{downloadTips}</p>
            <div className='downloadlist'>
                {
                    downloadList.map((item, index) => {
                        return (
                            <div className='download-item' key={index} onClick={()=>toDownload(item)}>
                                <img src={item.img} alt=''></img>
                                <span>{item.name}</span>
                            </div>
                        )
                    })
                }
            </div>
            <div className='viplayout'>
                <p style={{ color: "#fff", fontSize: "40px" }}>{t('download.joinvip')}</p>
                <div className='viplist'>
                    {
                        vipList.map((item, index) => {
                            return (
                                <div className='vip-item' key={index}>
                                    <img src={item.img} alt=''></img>
                                    <span>{item.content}</span>
                                </div>
                            )
                        })
                    }
                </div>
                <p style={{ color: "#fff", fontSize: "40px" }}>{t('download.how')}</p>
                <span style={{color:"rgba(255,255,255,0.6)",fontSize:"20px",marginBottom:"60px"}}>{t('download.keep3step')}</span>
                <div className='viplist'>
                    {
                        clientList.map((item, index) => {
                            return (
                                <div className='vip-item' key={index}>
                                    <img src={item.img} alt=''></img>
                                    <p>{item.content}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )

}

export default Download